<div class="not-found__container">
    <div class="not-found__background-image-container">
        <img src="../../../../assets/images/404.svg" alt="not-found" class="not-found__logo">
        <div class="not-found__text">
            Страница не найдена
        </div>
    </div>
    <button
        mat-stroked-button
        type="button"
        color="primary"
        (click)="redirect()"
        class="btn btn--uppercase mr-3">
        На главную
  </button>
</div>