import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs';

import { User } from 'src/app/shared/interfaces';
import { UserManagementService } from '@user-management/user-management.service';

@Injectable({ providedIn: 'root' })
export class CurrentUserResolver implements Resolve<User> {

  constructor(private service: UserManagementService) {}

  public resolve(): Observable<User> {
    return this.service.getCurrentUser()
  }

}
