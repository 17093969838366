import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@shared/services/auth/auth.service'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(
    private router: Router,
    private authService: AuthService
    ) {}

  public redirect(): void{
    const commands = this.authService.isAuthenticated() ?
      '/dashboard/tasks/list/table' :
      '/auth/login';

    this.router.navigate([commands]).then();
  }
}
