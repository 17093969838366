import { ToastMessagesService } from './toast-messages.service'
import { ErrorMessage } from '@shared/constants/messages/error.messages'
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ErrorMessageService {

    constructor(private toast: ToastMessagesService) {}

    public viewMessageOrDefault({error, status}): void {
        this.toast.showErrorMessage(ErrorMessage.DEFAULT);
    }

    public create({error, status}, controlMap?) {
        const statusPrefix = status.toString()[0]

        if(statusPrefix == '4' && error.errors && error.errors.messages) {
            this.toast.showErrorMessage(error.errors.messages);
        }else if(statusPrefix == '4' && Object.keys(error.errors).length > 0) {
            this.handleUnprocessableEntity(error, controlMap)
        }else {
            this.toast.showErrorMessage(ErrorMessage.DEFAULT);
        }
    }

    public createByCodeMap({status}, map) {
        const errorMessage = map[status.toString()] || ErrorMessage.DEFAULT

        this.toast.showErrorMessage(errorMessage)
    }

    private handleUnprocessableEntity(error, controlMap?) {
        for(var key in error.errors) {
            if(controlMap && controlMap[key]) {
                controlMap[key].control.setErrors({[controlMap[key].error]: error.errors[key]})
                controlMap[key].control.markAllAsTouched()
            }
        }
    }

}
