import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AuthService, DeviceAdaptService } from 'src/app/shared/services';
import { UserManagementService } from '@user-management/user-management.service';
import { ImpersonationModalComponent } from 'src/app/views/main/components/impersonation-modal/impersonation-modal.component'
import { User } from '@shared/interfaces';
import { ActivatedRoute } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit, OnDestroy {

  @Input() public isMobileSize: boolean = false;
  @Output() public mobileClicked: EventEmitter<any> = new EventEmitter<any>()

  public user: User;
  public userNameLabel: string = ''
  private currentUserSub: Subscription

  constructor(
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private authService: AuthService,
    private usersService: UserManagementService,
    public adaptService: DeviceAdaptService,
    public route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.getResolvedData();
  }

  public getResolvedData(): void {
    this.user = this.route.snapshot.data.user;
    this.userNameLabel = this.getUserLabel(this.user.name)

  }

  public openDialog() {
    this.dialog.open(ImpersonationModalComponent)
  }

  public clickMobileMenu() {
    this.mobileClicked.emit()
  }

  public getUserLabel(name: string) {
    return name.split(' ').slice(0, 2).map(str => str[0]).join('').toUpperCase()
  }

  public logout(){
    this.authService.logout()
  }

  public ngOnDestroy(): void {
    this.currentUserSub?.unsubscribe()
  }
}
