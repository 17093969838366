import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { env } from 'src/app/config/env.config';
import { JWTService } from './jwt-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private uri = env.api.endpoint;

  constructor(
    private http: HttpClient,
    private router: Router,
    private JWT: JWTService,
    public dialog: MatDialog
  ) {}

  public login(username: string, password: string) {
    const body = {
      scope: '',
      client_id: env.api.client_id,
      client_secret: env.api.client_secret,
      grant_type: 'password'
    }

    return this.http.post(this.uri + '/auth/token', {
      username,
      password,
      ...body
    })
  }

  public sendResetRequest(email: string) {
    return this.http.post(this.uri + '/auth/reset', {email})
  }

  validateResetToken(token: string) {
    return this.http.get(`${this.uri}/auth/reset/${token}/validate`)
  }

  public changePassword(data: any) {
    return this.http.put(`${this.uri}/auth/reset`, data)
  }

  public setAuth(data: any) {
    this.JWT.setTokens(data)
  }

  public logout() {
    this.JWT.clearTokens()
    this.dialog.closeAll();
    this.router.navigate(['auth', 'login']).then();
  }

  public isAuthenticated(): boolean {
    const token = this.JWT.getAccessToken()

    return token !== null;
  }

}
