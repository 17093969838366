// export const JwtService = {
//     getAccessToken: () => window.localStorage.getItem('access_token'),
//     setAccessToken: (token) => window.localStorage.setItem('access_token', token),
//     unsetAccessToken: () => window.localStorage.removeItem('access_token'),

//     getRefreshToken: () => window.localStorage.getItem('refresh_token'),
//     setRefreshToken: (token) => window.localStorage.setItem('refresh_token', token),
//     unsetRefreshToken: () => window.localStorage.removeItem('refresh_token'),
// };

import { Injectable } from '@angular/core';
import { LocalStorageHelper } from '../../helpers/local-storage.helper'

const ACCESS_TOKEN = 'access_token'
const ACCESS_TOKEN_IMPERSONATED = 'access_token_impersonated'
const REFRESH_TOKEN = 'refresh_token'
const REFRESH_TOKEN_IMPERSONATED = 'refresh_token_impersonated'

@Injectable({ providedIn: 'root' })
export class JWTService {

    private lcHelper: LocalStorageHelper = new LocalStorageHelper()

    public getAccessToken() {
        const token = this.tryGetImpersonatedToken()

        return token || this.lcHelper.get(ACCESS_TOKEN)//{type: token ? 'impersonate': 'default', value: token || this.lcHelper.get(ACCESS_TOKEN)}
    }

    public isImpersonationActive() {

    }

    private tryGetImpersonatedToken() {
        return this.lcHelper.get(ACCESS_TOKEN_IMPERSONATED)
    }

    public setTokens(data) {
        this.lcHelper.set(ACCESS_TOKEN, `${data.token_type} ${data.access_token}`)
        this.lcHelper.set(REFRESH_TOKEN, data.refresh_token)
    }

    public setImpersonationTokens(data) {
        this.lcHelper.set(ACCESS_TOKEN_IMPERSONATED, `${data.token_type} ${data.access_token}`)
        this.lcHelper.set(REFRESH_TOKEN_IMPERSONATED, data.refresh_token)
    }

    public clearImpersonationTokens() {
        this.lcHelper.delete(ACCESS_TOKEN_IMPERSONATED)
        this.lcHelper.delete(REFRESH_TOKEN_IMPERSONATED)
    }

    public clearTokens() {
        this.lcHelper.delete(ACCESS_TOKEN)
        this.lcHelper.delete(REFRESH_TOKEN)
    }
}
