<div class="flex-row flex__align-center h-100">
  <div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-3"></div>
      <div class="mdc-layout-grid__cell--span-6 text-center">
        <img src="../../../assets/images/logo_color.png"
             alt=""
             class="form-logo mb-3">
      </div>
      <div class="mdc-layout-grid__cell--span-3"></div>
    </div>
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-2"></div>
      <div class="mdc-layout-grid__cell--span-8 mdc-layout-grid__cell--align-middle">
        <router-outlet></router-outlet>
      </div>
      <div class="mdc-layout-grid__cell--span-2"></div>
    </div>
  </div>
</div>
