import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root'
})
export class ToastMessagesService {

  constructor(private toastr: ToastrService) {}

  showSuccessMessage(message: string): void {
    this.toastr.success(message)
  }

  showInfoMessage(message: string): void {
    this.toastr.info(message)
  }

  showErrorMessage(message: string): void {
    this.toastr.error(message)
  }
}
