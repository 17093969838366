import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResolverLoadingService } from 'src/app/shared/services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {

  constructor(private resolverLoadingService: ResolverLoadingService) {}

  public ngOnInit(): void {
    this.resolverLoadingService.subscribePreloader();
  }

  public ngOnDestroy(): void {
    this.resolverLoadingService.unsubscribe();
  }
}
