<div class="container position-relative">
  <main-toolbar
    [isMobileSize]="containerState == MainContainerState.SIDENAV_HIDDEN"
    (mobileClicked)="onMobileSideNavToggle()">
  </main-toolbar>

  <button
    *ngIf="containerState !== MainContainerState.SIDENAV_HIDDEN"
    class="sidenav__toggle"
    [class.sidenav__toggle--active]="containerState == MainContainerState.SIDENAV_OPEN"
    (click)="onSidebarToggle()"
    mat-icon-button>
    <mat-icon class="sidenav__toggle-icon" *ngIf="!isSidenavExpanded">
      chevron_right
    </mat-icon>
    <mat-icon class="sidenav__toggle-icon" *ngIf="isSidenavExpanded">
      chevron_left
    </mat-icon>
  </button>

  <mat-sidenav-container class="background-white sidenav-common-content-container">
    <mat-sidenav
      *ngIf="containerState == MainContainerState.SIDENAV_OPEN || containerState == MainContainerState.SIDENAV_CLOSE"
      opened
      [mode]="'side'"
      class="sidenav"
      [@onSideNavChange]="isSidenavExpanded ? 'open' : 'close'">
      <mat-nav-list class="overflow-y-auto">
        <mat-tree
          class="sidenav__tree"
          [dataSource]="menuDataSource"
          [treeControl]="menuControl">
          <mat-tree-node
            class="sidenav__tree__item"
            *matTreeNodeDef="let node">
            <a
              class="sidenav__item"
              [class.sidenav__item--active]="isCurrentLink(node.route)"
              mat-list-item
              routerLink="{{node.route}}">
              <mat-icon
                mat-list-icon
                [svgIcon]="node.icon">
              </mat-icon>

              <a
                mat-line
                class="sidenav__link"
                [class.sidenav__tree__item__action]="isCurrentLink(node.route)"
                [class.sidenav__tree__item--inner]="isSidenavExpanded">
                {{ node.title }}
              </a>
            </a>
          </mat-tree-node>
          <mat-tree-node class="sidenav__tree__item-action"
                         *matTreeNodeDef="let node; when: hasChild"
                         matTreeNodeToggle
                         matTreeNodePadding>
            <mat-list-item
              class="sidenav__item"
              [class.sidenav__item--active]="isParentOfActiveLink(node.title)"
              (click)="onNestedMenuClick()">
              <mat-icon
                mat-list-icon
                [svgIcon]="node.icon">
              </mat-icon>

              <p mat-line class="sidenav__link">
                {{ node.title }}
              </p>

              <button
                  mat-icon-button
                  class="sidenav__tree__item__action-btn"
                  [attr.aria-label]="'toggle ' + node.name">
                <mat-icon>
                  {{ menuControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
            </mat-list-item>
          </mat-tree-node>
        </mat-tree>
      </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav
      *ngIf="containerState == MainContainerState.SIDENAV_HIDDEN"
      opened
      mode="side"
      class="sidenav-mobile"
      [@onMobileSideNavChange]="isMobileSidenavExpanded ? 'open' : 'close'">
      <mat-nav-list class="overflow-auto w-100">
        <mat-tree
          class="sidenav-mobile__tree"
          [dataSource]="menuDataSource"
          [treeControl]="menuControl">
          <mat-tree-node
            class="sidenav-mobile__tree__item"
            *matTreeNodeDef="let node">

            <mat-list-item
              [class.color-primary]="isCurrentLink(node.route) && node.icon"
              [routerLink]="node.route"
              (click)="isMobileSidenavExpanded = false">
              <mat-icon
                mat-list-icon
                [svgIcon]="node.icon">
              </mat-icon>

              <a
                mat-line
                [class.sidenav-mobile__tree__item__action]="isCurrentLink(node.route)"
                matLine>
                {{ node.title }}
              </a>
            </mat-list-item>
          </mat-tree-node>
          <mat-tree-node
            class="sidenav-mobile__tree__item-action"
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodeToggle
            matTreeNodePadding>
            <mat-list-item [class.color-primary]="isParentOfActiveLink(node.title)">
              <mat-icon
                mat-list-icon
                [svgIcon]="node.icon">
              </mat-icon>

              <p
                mat-line>
                {{ node.title }}
              </p>
              <button
                  mat-icon-button
                  class="sidenav-mobile__tree__item__action-btn"
                  [attr.aria-label]="'toggle ' + node.name">
                <mat-icon>
                  {{ menuControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
            </mat-list-item>
          </mat-tree-node>
        </mat-tree>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content
      class="overflow-hidden"
      [class.ml-0]="this.containerState === MainContainerState.SIDENAV_HIDDEN"
      [@onMainContentChange]="containerState">
      <div class="mdc-layout-grid p-0">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell--span-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
