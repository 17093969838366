import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { OverlayPreloaderComponent } from '@shared/components/overlay-preloader/overlay-preloader.component';
import { DialogConfig } from '@shared/interfaces/dialog-config.interface'

const DEFAULT_CONFIG: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
}

@Injectable({
  providedIn: 'root'
})
export class OverlayPreloaderService {

  constructor(private overlay: Overlay) {}

  public open(): OverlayRef {
    const dialogConfig = { ...DEFAULT_CONFIG };
    const overlayRef = this.createOverlay(dialogConfig);
    const portal = new ComponentPortal(OverlayPreloaderComponent);
    overlayRef.attach(portal);
    return overlayRef;
  }

  private createOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: DialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }
}
