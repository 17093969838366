import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs';

import { DictionaryService } from '@dictionaries/dictionary.service';

@Injectable({ providedIn: 'root' })
export class StaticDictionariesResolver implements Resolve<any> {
  constructor(private service: DictionaryService) {}

  resolve(): Observable<any> {
    return this.service.getCachedDictionaries()
  }
}
