export enum ContactFormState {
  search = 'search',
  display = 'display',
  create = 'create',
  edit = 'edit',
  editRelType = 'edit-rel-type'
}

export const initialContactsData = [
    {
      state: 'search',
      data: {}
    }
]

export class ContactValidationMessage {
  public static phone_number = [
      { type: 'required', message: 'Поле \'Основной телефон\' обязательно для заполнения' },
      { type: 'dublicate', message: 'Контакт с таким номером телефона уже добавлен' }
  ]
}
