import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { debounceTime, delayWhen, filter, retryWhen, switchMap, tap } from 'rxjs/operators';

import { User } from 'src/app/shared/interfaces';
import { ApiDataService } from 'src/app/shared/services';

@Component({
  selector: 'app-impersonation-modal',
  templateUrl: './impersonation-modal.component.html',
  styleUrls: ['./impersonation-modal.component.scss']
})
export class ImpersonationModalComponent implements OnInit {

  public isSearching: boolean = false
  public loadedData: any[] = []
  public control: FormControl = new FormControl()
  public selectedUser: User = null

  private fieldSub: Subscription

  constructor(
    private http: ApiDataService,
    public currentDialog: MatDialogRef<ImpersonationModalComponent>
  ) {}

  public ngOnInit(): void {
    this.createSubscription();
  }

  public ngOnDestroy(): void {
    this.fieldSub?.unsubscribe()
  }

  public getListItemClass(itemId: number): string {
    const isSelectedUserIdEqualToOtherId = this.selectedUser && this.selectedUser.id == itemId;

    return isSelectedUserIdEqualToOtherId ? 'list-container__item list-container__item--active': 'list-container__item'
  }

  public inpersonate() {
    this.closeDialog()
  }

  public closeDialog() {
    this.currentDialog.close()
  }

  public selectUser(user) {
    this.selectedUser = user
  }

  public getInitialsLabel(name) {
    return name.split(' ').slice(0, 2).map(item => item[0]).join('')
  }

  public itemsNotFound() {
    const valueEntered = this.control.value && this.control.value.length > 0;

    return this.loadedData.length == 0 && !this.isSearching && valueEntered
  }

  private loadItems(val: string) {
    return this.http.get('/users', [{key: 'filter[email]', value: val}]);
  }

  private createSubscription(): void {
    this.fieldSub = this.control.valueChanges.pipe(
      filter(val => val),
      tap(() => this.isSearching = true),
      debounceTime(400),
      switchMap(val => val && this.loadItems(val)),
      retryWhen(errors => errors.pipe(delayWhen(_ => timer(500))))
    ).subscribe(
      data => {
        this.loadedData = data.data || []
        this.isSearching = false
      }
    )
  }

}
