import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DeviceAdaptService  {

  constructor(public breakpointObserver: BreakpointObserver) {}

  public isMobileDevice() {
    return this.breakpointObserver.isMatched('(max-width: 599px)');
  }

  public isTablet() {
    return this.breakpointObserver.isMatched('(max-width: 839px)');
  }

  public isDesktop() {
    return this.breakpointObserver.isMatched('(min-width: 1200px)');
  }
}
