import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { ROUTES } from 'src/app/app-routing.module'
import { AppComponent } from 'src/app/app.component'
import { MaterialModule } from 'src/app/shared/modules/material/material.module'
import { AuthInterceptor } from 'src/app/shared/interseptors/auth.interceptor';
import { OverlayPreloaderComponent } from '@shared/components/overlay-preloader/overlay-preloader.component';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    OverlayPreloaderComponent,
    NotFoundComponent
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    ROUTES,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    OverlayModule
  ],
  exports: [MaterialModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [OverlayPreloaderComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
