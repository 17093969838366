<div class="toolbar mdc-layout-grid">
    <div class="mdc-layout-grid__inner w-100">
        <div class="mdc-layout-grid__cell--span-6-desktop
                    mdc-layout-grid__cell--span-6-largedesktop
                    mdc-layout-grid__cell--span-2-phone
                    mdc-layout-grid__cell--span-4-tablet
                    mdc-layout-grid__cell--align-middle">
            <div class="flex-row flex__align-center">
                <button
                    *ngIf="isMobileSize"
                    mat-icon-button
                    (click)="clickMobileMenu()">
                    <mat-icon>
                        menu
                    </mat-icon>
                </button>
                <img
                    src="assets/images/logo_white.png"
                    class="toolbar__logo"
                    alt="User"
                />
            </div>

        </div>

        <div class="mdc-layout-grid__cell--span-6-desktop
                    mdc-layout-grid__cell--span-6-largedesktop
                    mdc-layout-grid__cell--span-2-phone
                    mdc-layout-grid__cell--span-4-tablet
                    mdc-layout-grid--align-right
                    mdc-layout-grid__cell--align-middle">
            <div class="flex-row">
                <div
                  class="flex-row flex__align-center"
                  #trigger="matMenuTrigger"
                  [matMenuTriggerFor]="userMenu">
                    <div class="color-primary toolbar__user-label">
                        {{ userNameLabel }}
                    </div>
                    <mat-icon
                      [svgIcon]="trigger.menuOpen? 'chevron-up': 'chevron-down'"
                      class="color-white toolbar__icon ml-1">
                    </mat-icon>
                </div>

                <button
                  mat-icon-button
                  aria-label="Logout"
                  (click)="logout()"
                  class="ml-2">
                    <mat-icon svgIcon="exit-to-app">
                    </mat-icon>
                </button>
            </div>


            <mat-menu #userMenu="matMenu">
              <button
                mat-menu-item
                routerLink="/dashboard/user-management/users/{{user.id}}/view">
                Профиль
              </button>
              <button mat-menu-item (click)="openDialog()">
                Имперсонализация
              </button>
            </mat-menu>
        </div>
    </div>
</div>
