<div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-12">
        <div class="heading--main">
            Имперсонализация
        </div>
    </div>
</div>

<div class="mdc-layout-grid__inner mt-3">
    <div class="mdc-layout-grid__cell--span-12 impersonation__input-container">
        <mat-form-field>
            <mat-label class="form__label">
                Введите email пользователя
            </mat-label>
            <input type="text"
                [formControl]="control"
                matInput
                placeholder="Введите номер или Email"
            />
        </mat-form-field>
    </div>
</div>

<div class="mdc-layout-grid__inner mt-2">
    <div class="mdc-layout-grid__cell--span-12">
        <div class="list-container">
            <div
                *ngFor="let item of loadedData"
                [class]="getListItemClass(item.id)"
                (click)="selectUser(item)">
                <img
                    *ngIf="item.full_image_path"
                    [src]="item.full_image_path"
                    class="list-container__photo"
                >
                <div
                    *ngIf="!item.full_image_path"
                    class="list-container__photo list-container__photo--stub">
                    {{ getInitialsLabel(item.name) | uppercase }}
                </div>
                <div class="ml-2">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mdc-layout-grid__inner mt-3">
    <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid--align-right">
        <button
            mat-button
            class="btn btn--uppercase mr-3"
            (click)="closeDialog()">
            ОТМЕНИТЬ
        </button>
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!selectedUser"
            (click)="inpersonate()"
            class="btn btn--uppercase">
            ВОЙТИ
        </button>
    </div>
</div>
