import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import {
  onMainContentChange,
  onMobileSideNavChange,
  onSideNavChange
} from 'src/app/shared/animations/side-nav.animations';

import { NavigationFlatNode, NavigationNodeInterface } from 'src/app/shared/interfaces';

import { DeviceAdaptService } from 'src/app/shared/services';
import { NAVIGATION_LIST } from 'src/app/shared/constants/navigation.constants';
import { MAX_MOBILE_WIDTH } from 'src/app/shared/constants/adapt.constants';

export enum MainContainerState {
  SIDENAV_OPEN = 'open',
  SIDENAV_CLOSE = 'close',
  SIDENAV_HIDDEN = 'full-size'
}

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    onSideNavChange,
    onMobileSideNavChange,
    onMainContentChange
  ],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy {

  public isSidenavExpanded: boolean = false;
  public isMobileSidenavExpanded: boolean = false;

  public MainContainerState = MainContainerState
  public containerState: MainContainerState = MainContainerState.SIDENAV_CLOSE
  private menu: NavigationNodeInterface[] = NAVIGATION_LIST
  private routeSub: Subscription

  public menuControl = new FlatTreeControl<NavigationFlatNode>(
    node => node.level,
    node => node.expandable
  );

  public menuFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  public menuDataSource = new MatTreeFlatDataSource(
    this.menuControl,
    this.menuFlattener
  );

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    public adaptService: DeviceAdaptService,
  ) {
    this.menuDataSource.data = this.menu;
    this.subscribeRouteChanges();
  }

  public ngOnInit(): void {
    this.setMainContainerState()
  }

  private subscribeRouteChanges(): void {
    this.routeSub = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.getCurrentRouteRecursively()),
        mergeMap(route => route.data)
      ).subscribe(
        data => this.changePageSubTitle(data?.title)
      );
  }

  private getCurrentRouteRecursively(): ActivatedRoute {
    let route = this.route.firstChild;
    let child = route;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
        route = child;
      } else {
        child = null;
      }
    }

    return route;
  }

  @HostListener('window:resize', ['$event'])
  private setMainContainerState(): void {
    if(window.innerWidth > MAX_MOBILE_WIDTH) {
      this.menuControl.collapseAll()
      this.containerState = MainContainerState.SIDENAV_CLOSE
      this.isMobileSidenavExpanded = false
    } else {
      this.containerState = MainContainerState.SIDENAV_HIDDEN
    }

    this.isSidenavExpanded = false
  }

  private changePageSubTitle(subTitle: string) {
    const defaultAppName = 'BabyPhotoStar'
    const subTitleString = subTitle ? ` | ${subTitle}` : '';

    this.titleService.setTitle(`${defaultAppName}${subTitleString}`)
  }

  private _transformer(node: NavigationNodeInterface, level: number) {
    return {
      expandable: !!node.children && node.children.length > 0,
      title: node.title,
      route: node.route,
      icon: node.icon,
      level
    };
  }

  public isCurrentLink(url: string) {
    return this.router.url.split('?')[0].includes(url[0]);
  }

  public hasChild(_: number, node: NavigationFlatNode): boolean{
    return node.expandable
  }

  public isParentOfActiveLink(title: string) {
    return this.menu
      .find(item => item.title === title)
      .children
      .filter(item => this.router.url.split('?')[0].includes(item.route[0]))[0];
  }

  public onNestedMenuClick() {
    if(!this.isSidenavExpanded) {
      this.onSidebarToggle()
    }
  }

  public onMobileSideNavToggle() {
    this.isMobileSidenavExpanded = !this.isMobileSidenavExpanded
  }

  public onSidebarToggle() {
    if(this.isSidenavExpanded) {
      this.menuControl.collapseAll()
      this.containerState = MainContainerState.SIDENAV_CLOSE
    }else {
      this.containerState = MainContainerState.SIDENAV_OPEN
    }
    this.isSidenavExpanded = !this.isSidenavExpanded
  }

  public ngOnDestroy(): void {
    this.routeSub?.unsubscribe()
  }
}
