import { Injectable } from '@angular/core';

import { ContactFormState } from '@shared/constants/contacts.constants'

@Injectable({
  providedIn: 'root'
})
export class ContactsFormDataService {

  constructor() { }

  public getSearchContact() {
    return {
      state: ContactFormState.search,
      data: {
        phone_number: '',
        first_name: '',
        last_name: '',
        relation_type: '',
        id:             '',
        email:          '',
        position_title: ''
      },
      fullData: null
    }
  }

  public cancelEdit(contacts, index: number) {
    contacts[index].state = 'display'
  }

  public cancelCreating(contacts, index: number) {
    contacts[index].state = 'search'
  }

  public getSearchLabel(index: number) {
    return index === 0 ?
      'Найти существующий контакт':
      'Добавить контакт'
  }

  public getContactLabel(index: number) {
    return index === 0 ?
      'Основной':
      'Дополнительный'
  }

  public matchContactData(data, keyword) {
    const phonesMatch = data.phones.filter(item => item.number.indexOf(keyword) != -1)[0]
    const emailsMatch = data.emails.filter(item => item.email.indexOf(keyword) != -1)[0]

    return phonesMatch ? {phone_number: phonesMatch.number}: emailsMatch ? {email: emailsMatch.email}: {}
  }

  public validateData(contacts) {
    const contactsNotSubmitted = contacts.filter((item, index: number) => {
      return item.state === 'edit-rel-type' ||
        item.state === 'create' ||
        item.state === 'edit' ||
        (item.state === 'search' && this.isContactSelected(item));
    })

    if(contactsNotSubmitted.length > 0) {
      return 'Сохраните все формы контактов или очистите ненужные';
    }

    const displayCount = contacts.filter(item => item.state == ContactFormState.display).length
    if(displayCount < 1) {
      return 'Должен быть минимум 1 сохраненный контакт';
    }

    const displayWithoutNumberCount = contacts
      .filter(item => item.state === ContactFormState.display && !item.data.phone_number).length

    if(displayWithoutNumberCount > 0) {
      return 'Заполните поле номер во всех формах контактов';
    }

    return null
  }

  public isContactSelected(contact) {
    return contact?.data && Object.values(contact?.data).filter(item => item).length
  }
}
