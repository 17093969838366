
import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { OverlayRef } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators'

import { OverlayPreloaderService } from '@shared/services/preloaders/overlay-preloader.service';
import { Subscription } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ResolverLoadingService {

    private overlayRef: OverlayRef
    private subscription: Subscription

    constructor(
        private router: Router,
        private overlayPreloader: OverlayPreloaderService
    ) {}

    public subscribePreloader(): Subscription {
        this.subscription = this.router.events
            .pipe(
                filter((e): e is RouterEvent => e instanceof RouterEvent)
            )
            .subscribe(
                (routerEvent: RouterEvent): void => this.checkRouterEvent(routerEvent)
            );

        return this.subscription;
    }

    public unsubscribe(): void {
        this.overlayRef?.dispose();
        this.subscription?.unsubscribe();
    }

    private checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.openOverlay();
        }

        if (this.isNavigationEnded(routerEvent)) {
            this.closeOverlay();
        }
    }

    private isNavigationEnded(routerEvent: RouterEvent): boolean {
        return (
            routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError
        );
    }

    private openOverlay(): void {
        if(!this.overlayRef) {
            this.overlayRef = this.overlayPreloader.open();
        } else {
            this.closeOverlay();
            this.overlayRef = this.overlayPreloader.open();
        }
    }

    private closeOverlay(): void {
        this.overlayRef?.dispose();
    }
}
