import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogConfig } from '@shared/interfaces/dialog-config.interface'


const DEFAULT_CONFIG: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
}

// export const PORTAL_DATA = new InjectionToken<any>('PortalData');

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) { }

  public prepareOverlayRef() {
    const dialogConfig = { ...DEFAULT_CONFIG }

    return this.createOverlay(dialogConfig)
  }

  public attachToRef(overlayRef: OverlayRef, component, data?) {
    let portal = data ? new ComponentPortal(component, null, this.createInjector(data)): new ComponentPortal(component)
    overlayRef.attach(portal)

    return overlayRef
  }

  public open(component, data?) {
    const dialogConfig = { ...DEFAULT_CONFIG };
    const overlayRef = this.createOverlay(dialogConfig);

    let portal
    if(data) {
      portal = new ComponentPortal(
        component,
        null,
        this.createInjector(data)
      );
    }else {
      portal = new ComponentPortal(component);
    }

    overlayRef.attach(portal);
    return overlayRef;
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap<any, any>([
      [MAT_DIALOG_DATA, data],
    ]);
    return new PortalInjector(this.injector, injectorTokens);
  }

  private createOverlay(config: DialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: DialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
