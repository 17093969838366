export class LocalStorageHelper {
    
    public get(key) {
        return window.localStorage.getItem(key)
    }

    public set(key, value) {
        window.localStorage.setItem(key, value)
        
        return value
    }

    public delete(key) {
       const item = window.localStorage.getItem(key)
       window.localStorage.removeItem(key)
        
       return item
    }
}