import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiDataService } from '@shared/services/utility/api-data.service';
import { UserRequestCollection } from '@shared/interfaces/users/user-request-collection';
import { Query } from '@shared/interfaces/utilities/query-parameter.interface';
import { User } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  public readonly USERS_BASE = '/users';
  public readonly USER_ABSENCES_BASE = '/users/absences';

  constructor(private apiDataService: ApiDataService) {}

  public getUsers(query: Query[]): Observable<UserRequestCollection> {
    return this.apiDataService.get(this.USERS_BASE, query);
  }

  public getCurrentUser(): Observable<User> {
    return this.apiDataService.get(`${this.USERS_BASE}/me`);
  }

  public getUserById(id: number): Observable<User> {
    return this.apiDataService.get(`${this.USERS_BASE}/${id}`);
  }

  public createUser(data: any): Observable<User> {
    return this.apiDataService.post(this.USERS_BASE, data);
  }

  public updateUser(id: string, data: any): Observable<User> {
    return this.apiDataService.patch(`${this.USERS_BASE}/${id}`, data);
  }

  public deactivateUser(id: number): Observable<undefined> {
    return this.apiDataService.patch(`${this.USERS_BASE}/${id}/deactivate`, null);
  }

  public attachPhotoToUser(id: number, formData: any) {
    return this.apiDataService.post(`${this.USERS_BASE}/${id}/photo`, formData);
  }

  public detachPhotoFormUser(id: number) {
    return this.apiDataService.delete(`${this.USERS_BASE}/${id}/avatar`);
  }

  // Absences
  public getAbsences(data: any): Observable<any[]> {
    return this.apiDataService.get(`${this.USER_ABSENCES_BASE}`, data);
  }

  public requestAbsence(data: any): Observable<undefined> {
    return this.apiDataService.post(`${this.USER_ABSENCES_BASE}`, data);
  }

  public requestAbsenceForCurrentUser(data: any): Observable<undefined> {
    return this.apiDataService.post(`${this.USER_ABSENCES_BASE}/request`, data);
  }

  public updateAbsence(id: string, data: any): Observable<undefined> {
    return this.apiDataService.patch(`${this.USER_ABSENCES_BASE}/${id}`, data);
  }

  public getAbsence(id: string): Observable<undefined> {
    return this.apiDataService.get(`${this.USER_ABSENCES_BASE}/${id}`);
  }

  public deleteAbsence(id: string): Observable<undefined> {
    return this.apiDataService.delete(`${this.USER_ABSENCES_BASE}/${id}`);
  }
}
