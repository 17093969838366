import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ListReloaderService {
  private subject = new Subject<HttpParams>()

  public reload(params?: HttpParams) {
    this.subject.next(params)
  }

  public clear() {
    this.subject.next(null)
  }

  public get reloadEventSent(): Observable<any> {
    return this.subject
  }
}
