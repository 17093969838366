
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RelatedListOverlayData } from '@shared/interfaces'

const stub:RelatedListOverlayData = {
  contact: {
    state: 'search',
    data: {}
  },
  closeOverlay: ()=> {}
}

@Injectable()
export class RelatedListOverlayDataService {

  private overlayDataSubject: BehaviorSubject<RelatedListOverlayData>;

  constructor() {
    this.overlayDataSubject = new BehaviorSubject(stub);
  }

  public setData(data) {
    this.overlayDataSubject.next(data);
  }

  public clearData() {
    this.overlayDataSubject.next(null)
  }

  public getData() {
    return this.overlayDataSubject.getValue()
  }

}
