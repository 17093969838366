import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from '@views/auth/auth.component'
import { MainComponent } from '@views/main/main.component'

import { AuthGuardService, NegateAuthGuardService } from '@shared/services'
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { StaticDictionariesResolver } from '@shared/resolvers/static-dictionaries.resolver';
import { CurrentUserResolver } from '@shared/resolvers/current-user.resolver';

export const routes: Routes = [
  {
    path: 'dashboard',
    component: MainComponent,
    loadChildren: () => import('./views/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuardService],
    // runGuardsAndResolvers: 'always',
    resolve: {
      dictionaries: StaticDictionariesResolver,
      user: CurrentUserResolver
    },
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [NegateAuthGuardService],
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

export const ROUTES = RouterModule.forRoot(
  routes,
  {
    onSameUrlNavigation: 'reload'
  }
);
