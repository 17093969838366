// Dictionaries
export const DICTIONARY_DYNAMIC_APPEARANCE_TYPE = '/dictionaries/dynamic/leads/appearances'
export const DICTIONARY_DYNAMIC_ACTIVITY_TYPE = '/dictionaries/dynamic/partners/activity-types'
export const DICTIONARY_DYNAMIC_BANK_PURPOSE = '/dictionaries/dynamic/bank_accounts'

export const DICTIONARY_DYNAMIC_DEPARTMENTS = '/dictionaries/dynamic/departments'
export const DICTIONARY_DYNAMIC_DEPARTMENT_UPDATE_FN = (id) => `/dictionaries/dynamic/departments/${id}`

export const DICTIONARY_HOBBY_TYPES = '/dictionaries/dynamic/leads/hobby-types'
export const DICTIONARY_HOBBY_GROUPS = '/dictionaries/dynamic/leads/hobby-groups'
export const DICTIONARY_STATIC = '/dictionaries/static'

// Users
export const USERS_BASE = '/users'
export const USERS_FORM = '/users/store'
export const CURRENT_USER = '/users/me'
export const USER_ATTACH_PHOTO_URL_PART = '/photo/attach?_method=PUT'
export const USER_DEACTIVATE_FN = (id) => `${USERS_BASE}/${id}/deactivate`

// Shoot studios
export const SHOOT_STUDIO_BASE = '/shoot-studios'
export const SHOOT_STUDIO_VIEW_FN = (id) => `${SHOOT_STUDIO_BASE}/${id}/view`
export const SHOOT_STUDIO_STORE = '/shoot-studios/store'
export const SHOOT_STUDIO_RESERVE_FN = (studioId: number) =>  `${SHOOT_STUDIO_BASE}/${studioId}/reservations`
export const SHOOT_STUDIO_REMOVE_RESERVATION_FN = (studioId: number, bookingId: string) =>  `${SHOOT_STUDIO_BASE}/${studioId}/reservations/${bookingId}/release`
export const SHOOT_STUDIO_UPDATE_RESERVATION_FN = (studioId: number, bookingId: number) =>  `${SHOOT_STUDIO_BASE}/${studioId}/reservations/${bookingId}/update`

// Sys configs
export const SYS_CONFIGS_BASE = '/sys_configs';
export const SYS_CONFIGS_LIST = `${SYS_CONFIGS_BASE}/search`;
export const SYS_CONFIGS_STORE = `${SYS_CONFIGS_BASE}/store`;
export const SYS_CONFIGS_VIEW_FN = (id: string) => `${SYS_CONFIGS_BASE}/${id}/view`;
export const SYS_CONFIGS_UPDATE_FN = (id: string) => `${SYS_CONFIGS_BASE}/${id}/update`;
export const SYS_CONFIGS_DELETE_FN = (id: string) => `${SYS_CONFIGS_BASE}/${id}/delete`;

// Banking
export const BANKING_BASE = '/banking';
export const BANKING_ACCOUNTS_BASE = `${BANKING_BASE}/accounts`;
export const BANKING_ACCOUNTS_STORE = `${BANKING_ACCOUNTS_BASE}/store`;
export const BANKING_ACCOUNTS_VIEW_FN = (id: number) => `${BANKING_BASE}/accounts/${id}/view`;
export const BANKING_ACCOUNTS_UPDATE_FN = (id: number) => `${BANKING_BASE}/accounts/${id}/update`;
export const BANKING_ACCOUNTS_DELETE_FN = (id: number) => `${BANKING_BASE}/accounts/${id}/delete`;

// Contract
export const CONTRACTS_BASE = `/contracts`;
export const CONTRACTS_STORE = `${CONTRACTS_BASE}/store`;
export const CONTRACTS_VIEW_FN = id => `${CONTRACTS_BASE}/${id}/view`;
export const CONTRACTS_UPDATE_FN = id => `${CONTRACTS_BASE}/${id}/update`;
export const CONTRACTS_DELETE_FN = id => `${CONTRACTS_BASE}/${id}/delete`;
export const CONTRACTS_CONFIRM_PATCH_FN = id => `${CONTRACTS_BASE}/confirm/${id}`;
export const CONTRACTS_REJECT_PATCH_FN = id => `${CONTRACTS_BASE}/reject/${id}`;


// Partners
export const PARTNERS_BASE = '/partners'
export const PARTNERS_POST = '/partners/store'

// Locations
export const LOCATIONS = '/locations'
export const LOCATION_BY_ID = '/locations/get'

// Shoots
export const SHOOT_BASE           = '/shoots'
export const SHOOT_VIEW_FN        = (id) => `/shoots/${id}/view`
export const SHOOT_TRANSFER_FN        = (id) => `/shoots/${id}/transfer`
export const SHOOT_CREATE         = '/shoots/store'
export const RECORD_LEAD_TO_SHOOT = '/leads/applications/shoot/assign'

// Leads
export const LEADS_APPLICATION_BASE  = '/leads/applications'
export const LEADS_APPLICATION_STORE = '/leads/applications/store'

export const LEAD_PARTICIPANT_BASE = '/leads/participants'

export const LEAD_PROFILE_BASE  = '/leads/profiles'
export const LEAD_PROFILE_STORE = '/leads/profiles/store'

// Contacts
export const CONTACT_BASE = '/contacts'

// Tasks
export const TASK_BASE                  = '/tasks'
export const TASKS_STORE                = '/tasks/store'
export const TASK_VIEW_FN               = (id) => `/tasks/${id}/view`
export const TASK_ATTACHMENT_ATTACH_FN  = (id) => `/tasks/${id}/attachment/attach`
export const TASK_UPDATE_FN             = (id) => `/tasks/${id}/update`
export const TASK_UPDATE_STATUS_FN      = (id) => `/tasks/${id}/status/update`
export const TASK_ADD_COMMENT_FN        = (id) => `/tasks/${id}/comments/store`
export const TASK_UPDATE_COMMENT_FN     = (taskId, commentId) => `/tasks/${taskId}/comments/${commentId}/update`
