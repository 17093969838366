import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { JWTService, AuthService } from 'src/app/shared/services'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private auth: AuthService,
    private JWT: JWTService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.JWT.getAccessToken()
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: accessToken
        }
      });
    }

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
        if (err.status !== 401 && err.status !== 403) {
         return;
        }

        this.auth.logout()
      }
    ));
  }
}
