import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { ApiDataService } from '@shared/services'
import {
  DICTIONARY_DYNAMIC_DEPARTMENTS,
  DICTIONARY_DYNAMIC_APPEARANCE_TYPE,
  DICTIONARY_DYNAMIC_ACTIVITY_TYPE,
  DICTIONARY_HOBBY_TYPES,
  DICTIONARY_HOBBY_GROUPS,
  DICTIONARY_STATIC,
  DICTIONARY_DYNAMIC_BANK_PURPOSE
} from '@shared/constants/endpoint.constants'

import { DictionaryEntry } from '@shared/interfaces/dictionary/dictionary.interface';
import { DynamicDictionaryRequestCollection } from '@shared/interfaces/dictionary/dictionary-request-collection';
import { Query } from '@shared/interfaces/utilities/query-parameter.interface';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  private staticDictionaries: Observable<{}>;

  constructor(private apiDataService: ApiDataService) {}

  public createAppearanceType(body): Observable<DictionaryEntry> {
    return this.apiDataService.post(DICTIONARY_DYNAMIC_APPEARANCE_TYPE, body)
  }

  public updateAppearanceType(slug: string, body): Observable<DictionaryEntry>  {
    return this.apiDataService.patch(`${DICTIONARY_DYNAMIC_APPEARANCE_TYPE}/${slug}`, body)
  }

  public createActivityType(body): Observable<DictionaryEntry> {
    return this.apiDataService.post(DICTIONARY_DYNAMIC_ACTIVITY_TYPE, body)
  }

  public updateActivityType(slug: string, body): Observable<DictionaryEntry> {
    return this.apiDataService.patch(`${DICTIONARY_DYNAMIC_ACTIVITY_TYPE}/${slug}`, body)
  }

  public getActivityTypes(query?: Query[]): Observable<DynamicDictionaryRequestCollection> {
    return this.apiDataService.get(DICTIONARY_DYNAMIC_ACTIVITY_TYPE, query)
  }

  public createBankAccountPurpose(body): Observable<DictionaryEntry> {
    return this.apiDataService.post(DICTIONARY_DYNAMIC_BANK_PURPOSE, body)
  }

  public updateBankAccountPurpose(id: number, body): Observable<DictionaryEntry> {
    return this.apiDataService.patch(`${DICTIONARY_DYNAMIC_BANK_PURPOSE}/${id}`, body)
  }

  public getBankAccountPurpose(query?: Query[]): Observable<DynamicDictionaryRequestCollection> {
    return this.apiDataService.get(DICTIONARY_DYNAMIC_BANK_PURPOSE, query)
  }

  public getHobbyTypes(query?: Query[]) {
    return this.apiDataService.get(DICTIONARY_HOBBY_TYPES, query)
  }

  public getHobbyGroups(query?: Query[]) {
    return this.apiDataService.get(DICTIONARY_HOBBY_GROUPS, query)
  }

  public getActivityTypeBySlug(slug: string): Observable<DictionaryEntry>  {
    return this.apiDataService.get(`${DICTIONARY_DYNAMIC_ACTIVITY_TYPE}/${slug}`)
  }

  public getStaticDictionaries() {
    return this.apiDataService.get(DICTIONARY_STATIC)
  }

  public getCachedDictionaries(): Observable<{}> {

    // Cache it once if configs value is false
    if (!this.staticDictionaries) {
      this.staticDictionaries = this.apiDataService.get(DICTIONARY_STATIC).pipe(
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    }

    return this.staticDictionaries;
  }

  public clearDictionariesCache(): void {
    this.staticDictionaries = null;
  }

  public getDepartments(): Observable<DynamicDictionaryRequestCollection> {
    return this.apiDataService.get(DICTIONARY_DYNAMIC_DEPARTMENTS)
  }

  public createDepartment(data: any) {
    return this.apiDataService.post('/dictionaries/dynamic/departments', data)
  }

  public updateDepartment(slug: string, data: any) {
    return this.apiDataService.patch(`${DICTIONARY_DYNAMIC_DEPARTMENTS}/${slug}`, data)
  }
}
