import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { env } from 'src/app/config/env.config';
import { AuthService } from '../auth/auth.service'
import {Query} from '@shared/interfaces/utilities/query-parameter.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  private uri = env.api.endpoint;
  private static toQueryString(arr: Query[]) {
    return arr && arr.length > 0 ? `?${arr.map(item => `${item.key}${item.oper || '='}${item.value}`).join('&')}`: '';
  }

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) {}

  get(url:string, params?: Query[], options?: unknown): Observable<any> {
    return this.http.get(`${this.uri}${url}${ApiDataService.toQueryString(params)}`, options)
  }

  // will replace get request when filter needed
  search(url:string, params: HttpParams): Observable<any> {
    return this.http.get(`${this.uri}${url}`, {params});
  }

  post(url: string, body: unknown, options?: unknown): Observable<any> {
    return this.http.post(`${this.uri}${url}`, body)
  }

  patch(url: string, body: unknown, options?: unknown): Observable<any> {
    return this.http.patch(`${this.uri}${url}`, body)
  }

  put(url: string, body: unknown, options?: unknown): Observable<any> {
    return this.http.put(`${this.uri}${url}`, body)
  }

  delete(url: string): Observable<any> {
    return this.http.delete(`${this.uri}${url}`)
  }
}
