import { trigger, state, style, transition, animate, animateChild, query, stagger } from '@angular/animations';

export const onSideNavChange = trigger('onSideNavChange', [
    state('close',
      style({
        'min-width': '60px'
      })
    ),
    state('open',
      style({
        'min-width': '280px'
      })
    ),
    transition('close => open', animate('250ms ease-in')),
    transition('open => close', animate('250ms ease-in')),
]);

export const onMobileSideNavChange = trigger('onMobileSideNavChange', [
  state('close',
    style({
      'left': '-100%'
    })
  ),
  state('open',
    style({
      'left': '0'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);

export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '65px'
    })
  ),
  state('open',
    style({
      'margin-left': '280px'
    })
  ),
  state('full-size',
    style({
      'margin-left': '0'
    })
  ),
  transition('full-size => close', animate('250ms ease-in')),
  transition('full-size => open', animate('250ms ease-in')),
  transition('close => full-size', animate('250ms ease-in')),
  transition('open => full-size', animate('250ms ease-in')),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);

export const animateLink = trigger('animateLink', [
    state('hide',
      style({
        'display': 'none',
        opacity: 0,
      })
    ),
    state('show',
      style({
        'display': 'block',
        opacity: 1,
      })
    ),
    transition('hide => show', [animate('500ms ease-in')]),
    transition('show => hide', [animate('500ms ease-in')]),
]);

export const displayAnimation = trigger('displayAnimation',[
  transition(':enter', [
    style({transform: 'translateY(1%)', opacity: 0}),
    animate('350ms', style({transform: 'translateX(0)', opacity: 1})),
  ]),
  transition(':leave', [
    style({transform: 'translateX(0)', opacity: 1}),
    animate('350ms', style({transform: 'translateY(1%)', opacity: 0})),
  ])
])

