import { NavigationNodeInterface } from 'src/app/shared/interfaces';

export const NAVIGATION_LIST: NavigationNodeInterface[] = [
  {
    title: 'Задачи',
    icon: 'calendar-check',
    children: [
      {
        title: 'Мои задачи',
        route: ['/dashboard/tasks']
      },
      {
        title: 'Мои звонки',
        route: ['/dashboard/calls']
      }
    ]
  },
  {
    title: 'Партнеры',
    icon: 'account-multiple',
    route: ['/dashboard/partners']
  },
  {
    title: 'Проекты',
    icon: 'star',
    route: ['/dashboard/projects']
  },
  {
    title: 'Органайзер',
    route: ['/dashboard/shoots/organizer'],
    icon: 'camera'
  },
  {
    title: 'Контакты',
    icon: 'contacts',
    route: ['/dashboard/contacts']
  },
  {
    title: 'Лиды',
    icon: 'account',
    children: [
      {
        title: 'Заявки',
        route: ['/dashboard/leads/applications']
      },
      {
        title: 'Анкеты',
        route: ['/dashboard/leads/profiles']
      },
      {
        title: 'Учасники',
        route: ['/dashboard/leads/participants']
      }
    ]
  },
  {
    title: 'Финансы',
    icon: 'bank',
    children: [
      {
        title: 'Контракты',
        route: ['/dashboard/finances/contracts']
      },
      {
        title: 'Подписанты',
        route: ['/dashboard/finances/bank-accounts']
      },
      {
        title: 'Предназначение счетов',
        route: ['/dashboard/finances/bank-account-purposes']
      }
    ]
  },
  {
    title: 'Настройки',
    icon: 'cogs',
    children: [
      {
        title: 'Список фотостудий',
        route: ['/dashboard/system-settings/shoot-studios']
      },
      // {
      //   title: 'Список отделов',
      //   route: ['/dashboard/system-settings/departments']
      // },
      {
        title: 'Список системных настроек',
        route: ['/dashboard/system-settings/sys-configs']
      }
    ]
  },
  {
    title: 'Пользователи',
    icon: 'account-box',
    children: [
      {
        title: 'Список пользователей',
        route: ['/dashboard/user-management/users']
      },
      {
        title: 'Права',
        route: ['/dashboard/user-management/permissions']
      },
      {
        title: 'Роли',
        route: ['/dashboard/user-management/roles']
      },
      {
        title: 'График работы',
        route: ['/dashboard/user-management/work']
      },
      {
        title: 'График дежурств',
        route: ['/dashboard/user-management/duty']
      },
    ]
  },
  {
    title: 'Словари',
    icon: 'book-open-blank-variant',
    children: [
      {
        title: 'Внешность',
        route: ['/dashboard/dictionaries/leads-appearance-types']
      },
      {
        title: 'Виды деятельности',
        route: ['/dashboard/dictionaries/partners-activity-types']
      }
    ]
  }
];
